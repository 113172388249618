export const resources = {
  en: {
    translation: {
      "title.share.your": "SHARE YOUR",
      "title.rookie.mistake": "ROOKIE MISTAKE",
      "button.click.start": "Click to Start",
      win: "WIN",
      big: "BIG",
      prices: "PRIZES",
      skip: "SKIP",
      retake: "Retake",
      next: "Next",
      "title.form.fill.your.details": "Please Fill Your Details",
      "label.input.caption": "Write a caption for your photo",
      "label.input.name": "Name",
      "label.input.mobile": "Mobile",
      "label.input.email": "Email",
      "label.input.region": "City",
      "btn.submit": "Submit",
      "title.thank.you1": "Thank You!",
      "title.thank.you2": "your submission",
      "desc.thank.you": "You have entered the draw to win A Family’s Trip to Warner Bros. Abu Dhabi.",
      "btn.continue": "Continue",
      "choose.your.region": "Choose your region",
      "terms.label": "I've read and accept",
      "terms.link": "Terms & Conditions"
    },
  },
  ar: {
    translation: {
      "title.share.your": "شارك",
      "title.rookie.mistake": "خطأ الصاعد",
      "button.click.start": "أضغط هنا للمشاركة",
      win: "يفوز",
      big: "كبير",
      prices: "الأسعار",
      skip: "يتخطى",
      retake: "إعادة",
      next: "التالي",
      "title.form.fill.your.details": "الرجاء تعبئة بياناتك",
      "label.input.caption": "أكتب تعليقك على صورتك",
      "label.input.name": "الاسم",
      "label.input.mobile": "الجوال",
      "label.input.email": "البريد الالكتروني",
      "label.input.region": "مدينة",
      "btn.submit": "تسجيل",
      "title.thank.you1": "!شكرًا لك",
      "title.thank.you2": "تقديمك",
      "desc.thank.you": "لقد دخلت السحب للفوز برحلة إلى وارنر برذرز في ابو ظبي",
      "btn.continue": "يكمل",
      "choose.your.region": "اختر منطقتك",
    },
  },
};
